import React from 'react'
import RBI from './components/RBI'
import CommonBanner from '../../components/CommonBanner'
import SoftwareProviderCards from './components/SoftwareProviderCards'

function FacilationCenter() {
    function getCurrentFormattedDate() {
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        const hour = String(now.getHours() % 12 || 12).padStart(2, '0');
        const minute = String(now.getMinutes()).padStart(2, '0');
        const ampm = now.getHours() >= 12 ? 'PM' : 'AM';

        return `${year}-${month}-${day} ${hour}:${minute} ${ampm}`;
    }

    const formattedDate = getCurrentFormattedDate();

    return (
        <div>
            <CommonBanner isSquare={false} isOltas title='TIN Facilitation Centres' />
            <RBI />
            <p className='flex flex-col pt-6 w-full md:px-[68px]'>Updated as on 2024-12-14 10:00 AM</p>
            <SoftwareProviderCards />
        </div>
    )
}

export default FacilationCenter